import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ header, className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "trees.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
            <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
            >
            <br/><br/>
            <br/><br/>
            <br/><br/>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  marginTop: 0;
`
export default StyledBackgroundSection