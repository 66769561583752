import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import StyledBackgroundSection from '../components/background'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div>
        <a href="https://www.amazon.com/Times-Square-Rabbi-Finding-Lives/dp/158832043X/ref=sr_1_2?dchild=1&keywords=yehudah+fine&qid=1593699385&s=books&sr=1-2" target="_blank" rel="noreferrer"><img src="https://imgur.com/J2uXOfP.png" id="image" style={{marginRight:`10px`}} alt="timessquarerabbi" /></a>
        <a href="https://www.amazon.com/Real-Deal-Parents-Questions-Answered/dp/1588321371/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1597004841&sr=1-2" target="_blank" rel="noreferrer"><img src="https://imgur.com/ATbDTsz.png" id="image" style={{marginRight:`10px`}} alt="realdeal" /></a>
        <a href="https://www.amazon.com/Shadow-Walker-Battles-Snatched-Trafficker/dp/1480833568/ref=sr_1_1?dchild=1&keywords=shadow+walker+yehuda+fine&qid=1597004682&s=books&sr=1-1" target="_blank" rel="noreferrer"><img src="https://imgur.com/NK1qGQ4.png" id="image" alt="shadowwalker" /></a>
        <hr/>
      <h1
        style={{
          ...scale(1.5), 
          marginBottom: rhythm(1.5),
          marginTop: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
        
      </h1>
    </div>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div>
      <div style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(40),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}><StyledBackgroundSection header={header}/></div>
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} Yehuda Fine
      </footer>
    </div>
    </div>
  )
}

export default Layout
